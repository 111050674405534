import { OPENPRISE_BOT_ID } from ':constants/env';
import { log, VercelLogMessage } from ':lib/vercel';

import {
  OpenpriseError,
  OpenpriseErrorSeverity
} from './errors/openprise-error';

export const CORRELATION_ID_HEADER = 'x-rippling-cid';

export const WWW_SERVICE_NAMESPACE = 'rippling-www';

export type FieldValidation = {
  expected: string;
  fieldName: string;
};

// Function used in the form submission handler on the client to the vercel server endpoint,
// which is used to proxy the request to Openprise
export const postToOpenpriseProxy = async (formData: Record<string, unknown>) => {
  return fetch('/api/www-vercel-openprise', {
    body: JSON.stringify(formData),
    headers: { 'Content-Type': 'application/json' },
    method: 'post',
  });
};

/**
 * Logging
 */
const logOpenpriseMessage = (message: Omit<VercelLogMessage, 'apiName'>) => {
  log({
    apiName: 'www-vercel-openprise',
    ...message,
  });
};

export const logOpenpriseErrorMessage = (error: OpenpriseError) => {
  const {
    contextualData, correlationId, errorCode, message, severity,
  } = error;

  logOpenpriseMessage({
    data: {
      contextualData,
      correlationId,
      errorCode,
    },
    message,
    type: severity === OpenpriseErrorSeverity.Error ? 'error' : 'warn',
  });
};

// Remove this mapping and "getFormDataWithCampaignName" once Marketo and p_name is deprecated
export const pNameToCampaignNameMap = {
  '2023-06-10 SHRM Conference': '2023-06-10 SHRM Conference',
  'contact-us': 'WF-Web Form-Contact Us',
  'content-lp': 'WF-Web Form-Content Webinars LP',
  'lp-home': 'WF-Web Form-Rippling Request Demo',
  'Partner Event 2022-06-08 AICPA Happy Hour':
    'Partner Event 2022-06-08 AICPA Happy Hour',
  peo: 'WF-Web Form-PEO Demo Request',
  peopleops: 'FB-Facebook Instapage',
  'product-tour': 'WF-Web Form-Product Tour',
  'quiz-program': 'WF-PEO Quiz Trigger Program',
  'request-demo': 'WF-Web Form-Rippling Request Demo',
  'request-quote': 'WF-Web Form-Rippling Request Quote',
  'shell-program': 'WF-xxDemo Request Shell Program',
  'simple-form': 'WF-Web Form-Rippling Demo Drop Off',
  subscribe: 'Product Update Subscribe',
  'ur-program': 'Beta and Research Subscribe',
  'video-tour': 'WF-Web Form-Product Tour',
  'WF-xxDemo Request Qualifying Data': 'WF-xxDemo Request Qualifying Data',
} as const;

const getCampaignName = (formData: Record<string, unknown>): string => {
  if (formData.campaign_name) {
    return formData.campaign_name as string;
  }

  if (formData.video_tour) {
    return pNameToCampaignNameMap['video-tour'];
  }

  if (formData.request_quote) {
    return pNameToCampaignNameMap['request-quote'];
  }

  if (formData.p_name) {
    const pName = (formData.p_name as string) ?? '';

    return (
      pNameToCampaignNameMap[pName] ||
      pNameToCampaignNameMap['request-demo']
    );
  }

  return pNameToCampaignNameMap['request-demo'];
};

export const addAdditionalDataToFormData = (formData: Record<string, unknown>) => {
  return {
    ...formData,
    bot_id: OPENPRISE_BOT_ID,
    campaign_name: getCampaignName(formData),
  };
};
