import React from 'react';

class Wistia extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPlayButton: false,
      videoStarted: false,
    };

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { attributes } = this.props;
    const { wistiaId } = attributes;

    if (!this.state.videoStarted) {
      var video = window.Wistia.api(wistiaId);
      video.play();
    }

    this.setState({ videoStarted: true });
  }

  componentDidMount() {
    // eslint-disable-next-line unicorn/no-this-assignment
    var that = this;
    var script = document.createElement('script');
    script.src = 'https://fast.wistia.com/assets/external/E-v1.js';
    document.head.append(script);
    window._wq = window._wq || [];

    window._wq.push({
      id: '_all',
      onReady: function(video) {
        window._wistiaVid = video;

        const showPlayButton = !video.options.autoPlay;
        that.setState({ showPlayButton });

        if (that.props.attributes.onReady) {
          new Function('video', that.props.attributes.onReady)(video);
        }

        video.bind('play', function() {
          that.setState({ videoStarted: true });
        });
      },
      options: {
        controlsVisibleOnLoad: false,
        playButton: false,
      },
    });
  }

  render() {
    const { attributes } = this.props;
    const { className, wistiaId } = attributes;
    const playButtonClass = this.state.showPlayButton
      ? 'wistia_embed__customPlay'
      : '';

    return (
      <div className={`wistiaEmbedWrapper ${className || ''}`}>
        <div
          className={`wistia_embed wistia_async_${wistiaId}${this.state.videoStarted ? ' -started' : ''} videoFoam=true ${playButtonClass}`}
          id={`wistiaId-${wistiaId}`}
        />
        {!this.state.videoStarted && (
          <div
            className="absolute w-100% h-100% top-0 cursor-pointer"
            onClick={this.onClick}
          />
        )}
      </div>
    );
  }
}

export default Wistia;
